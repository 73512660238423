import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import { batch, useSelector, useDispatch } from "react-redux";
import { setLastKnownEvent } from "../utilities/common/utils";
import {
  displayEngagementRailSelector,
  eventSelector,
  getEventAsyncAction,
  getEventSpeakersAsyncAction,
  getLogoImageAsyncAction,
  getPromoImageAsyncAction,
  getRegistrationStatusFromRegistrationIdAsyncAction,
  logoImageSelector,
  registeredSelector,
  registrationDetailSelector,
  userIsRegistered,
  resetEvent,
  setEventEngagementRailAction,
  eventErrorSelector,
  EventEngagementRailChoices,
  hasUserCancelledRegistrationSelector,
  getRegistrationDetailsAsyncAction,
  eventSpeakersSelector,
  getEventOverviewAsyncAction,
} from "../core/slices/eventSlice";
import {
  getEventSponsorsAsyncAction,
  eventSponsorsSelector,
} from "../core/slices/sponsorSlice";
import {
  enableWebinarSeriesSelector,
  enableSponsorsSelector,
  enableRedirectAcquireTokenSelector,
  enableCreateWebinarSelector,
  enableEventOverviewApiSelector,
} from "../core/slices/ecsSlice";
import { IError } from "../core/slices/error";
import {
  setEventSessionsDisplayedSpeakerAction,
  displayedSpeakerSelector,
} from "../core/slices/sessionSlice";
import {
  getMeAsyncAction,
  authenticatedSelector,
  userSelector,
  userErrorSelector,
} from "../core/slices/userSlice";
import {
  getEventSessionsAsyncAction,
  resetEventSessionsAction,
} from "../core/slices/sessionSlice";
import { getImageAsyncAction } from "../core/slices/imageSlice";
import {
  IEvent,
  EventType,
  EventState,
} from "../core/slices/eventTypes.interface";
import { EventNavBarWeb } from "./EventNavBarWeb";
import { EventLayout } from "./EventLayout";
import { EventRegistrationButton } from "./EventRegistrationButton";
import { CreateWebinarCard } from "./CreateWebinarCard";
import {
  ImageV9 as Image,
  getDocument,
  mergeClasses,
  TextV9 as Text,
  ArrowRepeatAllRegular,
  CalendarMultipleFilled,
} from "../shared";
import "../styles/EventPage.scss";
import { EventInfo } from "./eventInfo/EventInfo";
import { SpeakerProfileDialog } from "./SpeakerProfile";
import { EventSpeakers } from "./EventSpeakers";
import { EventSessions } from "./EventSessions";
import { EventSponsors } from "./EventSponsors";
import { titleEventRegBarStyles, eventPageStyles } from "../styles/EventPage";
import { logoImageStyles } from "../styles/LogoImage";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { HeroImage } from "./HeroImage";
import { useLogger } from "../common/logger/LoggerContext";
import { EventAttendingAndCancel } from "./registration/RegistrationCard";
import {
  IEventPageRegistrationModals,
  EventPageRegistrationModals,
} from "./registration/EventPageRegistrationModals";
import { CancelRegistration } from "./registration/CancelRegistration";
import { routes } from "../common/constants";
import { useTranslation } from "react-i18next";
import { useAuthenticationService } from "../core/auth/auth-context";
import { EventAddToCalenderButton } from "./EventAddToCalendarButton";
import { EventShareEventButton } from "./EventShareEventButton";
import { EventPageLoading } from "./loading/EventPageLoading";
import { PageViewCount } from "./PageViewCount";
import { eventErrorHandler } from "../utilities/eventErrorHandler";
import ScrollToTop from "../utilities/common/utils";
import { Breakpoint, gridStyles } from "../styles/Grid";
import { portalTextStyles } from "../styles/PortalText";
import { IEventInfo } from "./eventInfo/IEventInfo";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../styles/FlexStyles";
import { portalTagStyles } from "../styles/PortalTag";
import { isMultiSessionEventSelector } from "../core/slices/sessionSlice";
import { useAttendeeIdSearchParamHandlerHook } from "../utilities/hooks/useAttendeeIdSearchParamHandlerHook";
import { generateSearchParams } from "../utilities/common/generateSearchParams";
import { usePresenterKeySearchParamHandlerHook } from "../utilities/hooks/usePresenterKeySearchParamHandlerHook";
import { PortalLocationState } from "../core/history/history.interface";
import { iconStyles } from "../styles/IconStyles";

export const Event: React.FunctionComponent = (props) => {
  const titleEventRegBarClasses = titleEventRegBarStyles();
  const eventPageClasses = eventPageStyles();
  const logoImageClasses = logoImageStyles();
  const gridClasses = gridStyles();
  const portalTextClasses = portalTextStyles();
  const portalTagClasses = portalTagStyles();
  const flexAlignClasses = flexAlignStyles();
  const flexItemClasses = flexItemStyles();
  const flexClasses = flexStyles();
  const iconclasses = iconStyles();

  const logger = useLogger()?.logger;
  const document = getDocument();
  const referrer = document?.referrer;
  logger?.logUiTelemetry(
    "renderComponent",
    "ShowEvent",
    "none",
    "success",
    "Event",
    referrer
  );

  const { path } = useRouteMatch();
  const { id, regId } = useParams<{ id: string; regId: string }>();
  useAttendeeIdSearchParamHandlerHook();
  usePresenterKeySearchParamHandlerHook();
  const location = useLocation<PortalLocationState>();
  let currentEvent: IEvent | undefined = useSelector(eventSelector);
  const eventError: IError | undefined = useSelector(eventErrorSelector);
  const meError: IError | undefined = useSelector(userErrorSelector);
  const isAuthenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);
  const displayedSpeaker = useSelector(displayedSpeakerSelector);
  const registration = useSelector(registeredSelector);
  const registrationDetails = useSelector(registrationDetailSelector);
  const isRegistered = useSelector(userIsRegistered);
  const hasUserCancelledRegistration = useSelector(
    hasUserCancelledRegistrationSelector
  );
  const speakers = useSelector(eventSpeakersSelector);
  const sponsors = useSelector(eventSponsorsSelector);
  const displayEngagementRail = useSelector(displayEngagementRailSelector);
  const enableWebinarSeries = useSelector(enableWebinarSeriesSelector);
  const enableSponsors = useSelector(enableSponsorsSelector);
  const enableCreateWebinarButton = useSelector(enableCreateWebinarSelector);
  const enableEventOverviewApi = useSelector(enableEventOverviewApiSelector);
  const enableRedirectAcquireToken = useSelector(
    enableRedirectAcquireTokenSelector
  );
  const dispatch = useDispatch();
  const history = useHistory<PortalLocationState>();
  const { t: i18n } = useTranslation();
  let eventPromoImageID =
    currentEvent && currentEvent.theme.promoImage
      ? currentEvent.theme.promoImage
      : "";
  let eventLogoImageID =
    currentEvent && currentEvent.theme.logoImage
      ? currentEvent.theme.logoImage
      : "";

  const { isBreakpointAndDown } = useBreakpoint();
  const mobileView = isBreakpointAndDown(Breakpoint.Medium);
  const [loadedEventDetails, setLoadedEventDetails] = useState(false);
  const { authenticationService } = useAuthenticationService();
  const isMultiSessionEvent = useSelector(isMultiSessionEventSelector);

  ScrollToTop();
  // If the event ID has changed, but the currentEvent
  // in the store has not, we want to suppress the render
  if (currentEvent && currentEvent.id !== id) {
    currentEvent = undefined;
    eventPromoImageID = "";
    eventLogoImageID = "";
  }

  // Reset loaded state on authentication change
  useEffect(() => {
    setLoadedEventDetails(false);
  }, [isAuthenticated]);

  useEffect(() => {
    batch(() => {
      if (!loadedEventDetails) {
        // set our last known event
        setLastKnownEvent(id);
        setLoadedEventDetails(true);
        dispatch(resetEvent());
        // clear the sessions
        dispatch(resetEventSessionsAction(true));
        // get our identity
        if (isAuthenticated) {
          dispatch(getMeAsyncAction(true));
        }

        if (enableEventOverviewApi) {
          dispatch(getEventOverviewAsyncAction(id));
        } else {
          // get our new event
          dispatch(getEventAsyncAction(id));
          // get our speakers
          dispatch(getEventSpeakersAsyncAction(id));
          // get the sessions for the event
          dispatch(getEventSessionsAsyncAction(id));
          // get registration details so access type and route are available for registration button
          dispatch(getRegistrationDetailsAsyncAction(id));
          // get the sponsors for the event
          if (enableSponsors) {
            dispatch(getEventSponsorsAsyncAction(id));
          }
        }

        // get the registration status if registrationID is known
        // will return Unknown if redId not present
        if (regId && regId.length > 0) {
          dispatch(
            getRegistrationStatusFromRegistrationIdAsyncAction({
              eventId: id,
              registrationId: regId,
            })
          );
        }

        // needs a dispatch for the engagement rail.
        // This will not show chat engagement rail unless user is registered
        // based on selector in EventEngagementRail - useSelector(displayEngagementRailSelector);
        dispatch(setEventEngagementRailAction(EventEngagementRailChoices.Chat));
      }
    });
  }, [
    isAuthenticated,
    id,
    regId,
    dispatch,
    user,
    registration,
    loadedEventDetails,
    hasUserCancelledRegistration,
    enableEventOverviewApi,
    enableSponsors,
  ]);

  // handle any API errors
  useEffect(() => {
    if (loadedEventDetails) {
      eventErrorHandler(
        eventError || meError,
        isAuthenticated,
        enableRedirectAcquireToken,
        history,
        location
      );
    }

    //Special case for organizer as they don't get the 400 error when the event is cancelled.
    //They get the whole payload with just the state changed from Published to Canceled
    if (currentEvent?.state === EventState.CANCELED) {
      history.replace({
        pathname: routes.eventCanceled,
        search: generateSearchParams(location),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eventError,
    meError,
    history,
    id,
    isAuthenticated,
    enableRedirectAcquireToken,
    currentEvent,
    authenticationService,
    loadedEventDetails,
  ]);

  // prefetch our speaker images
  useEffect(() => {
    speakers.forEach((speaker) => {
      if (speaker.profileImage) {
        dispatch(getImageAsyncAction(speaker.profileImage));
      }
    });
  }, [speakers, dispatch]);

  // prefetch our sponsor images
  useEffect(() => {
    if (enableSponsors) {
      sponsors.forEach((sponsor) => {
        if (sponsor.logo) {
          dispatch(getImageAsyncAction(sponsor.logo));
        }
      });
    }
  }, [sponsors, dispatch, enableSponsors]);

  useEffect(() => {
    dispatch(getPromoImageAsyncAction(eventPromoImageID));
    dispatch(getLogoImageAsyncAction(eventLogoImageID));
  }, [dispatch, eventPromoImageID, eventLogoImageID]);

  const onDismissSpeakerProfileDialog: () => void = () => {
    dispatch(setEventSessionsDisplayedSpeakerAction(null));
  };

  const modalProps: IEventPageRegistrationModals = {
    isRegistered: isRegistered,
    registration: registration,
    currentEvent: currentEvent,
  };

  const logoImage = useSelector(logoImageSelector);

  const showRegistrationCard = currentEvent
    ? currentEvent.type === EventType.WEBINAR
    : false;
  const showWebinarLink =
    !!currentEvent &&
    currentEvent.type === EventType.WEBINAR &&
    authenticationService.userIsAuthenticated() &&
    enableCreateWebinarButton;
  const showRegistrationStatus = isRegistered && isAuthenticated;

  const isEventFull: boolean =
    !!registrationDetails?.registrationProperties?.isFull;
  const isEventWaitlistOpen: boolean = !!(
    registrationDetails?.registrationProperties?.isWaitlistEnabled &&
    !registrationDetails?.registrationProperties?.isWaitlistFull
  );

  const eventInfoProps: IEventInfo = {
    isFull: isEventFull,
    waitlistOpen: isEventWaitlistOpen,
  };

  let isEventHappeningNow = false;

  if (currentEvent && isRegistered && isAuthenticated) {
    const currentTime = new Date();
    const eventStartTime = new Date(currentEvent?.eventTime?.startTime);

    isEventHappeningNow = currentTime.getTime() >= eventStartTime.getTime();
  }

  let eventPageDisplay = null;

  if (!loadedEventDetails || !currentEvent) {
    eventPageDisplay = <EventPageLoading />;
  } else {
    eventPageDisplay = (
      <>
        <EventLayout>
          <div role="main" className="event-page-wrapper">
            <div className="event-page-header">
              <HeroImage />
              <div
                id="title-event-reg-bar"
                className={mergeClasses(
                  "title-event-reg-bar",
                  titleEventRegBarClasses.root
                )}
              >
                <div
                  className={mergeClasses(
                    gridClasses.container,
                    flexClasses.root,
                    flexClasses.rowGap24px
                  )}
                >
                  <div
                    className={mergeClasses(
                      flexClasses.root,
                      flexClasses.fill,
                      flexClasses.rowGap24px,
                      flexAlignClasses.alignItemCenter
                    )}
                  >
                    <Image
                      src={logoImage.image}
                      alt=""
                      className={mergeClasses(
                        logoImageClasses.root,
                        eventPageClasses.titleEventRegBarImage
                      )}
                    />
                    <div
                      className={mergeClasses(
                        flexClasses.root,
                        flexClasses.column,
                        flexClasses.columnGap2px,
                        flexItemClasses.grow,
                        flexAlignClasses.justifyContentCenter
                      )}
                    >
                      <div
                        className={mergeClasses(
                          flexClasses.root,
                          flexClasses.rowGapSmaller
                        )}
                      >
                        {enableWebinarSeries && (
                          <div
                            className={mergeClasses(
                              flexClasses.root,
                              flexAlignClasses.alignItemCenter,
                              portalTagClasses.root,
                              portalTagClasses.grey
                            )}
                            id="webinar-series"
                          >
                            <CalendarMultipleFilled
                              className={iconclasses.large}
                            />
                            &nbsp;
                            <Text weight="semibold">
                              {i18n("webinar_series_tag")}
                            </Text>
                          </div>
                        )}
                        {isMultiSessionEvent && (
                          <div
                            className={mergeClasses(
                              flexClasses.root,
                              flexAlignClasses.alignItemCenter,
                              portalTagClasses.root,
                              portalTagClasses.grey
                            )}
                            data-testid="webinar-multi-session"
                          >
                            <ArrowRepeatAllRegular
                              className={iconclasses.medium}
                            />
                            &nbsp;
                            <Text weight="semibold">
                              {i18n("webinar_multi_tag")}
                            </Text>
                          </div>
                        )}
                        {isEventHappeningNow && (
                          <div
                            className={mergeClasses(
                              flexClasses.root,
                              flexAlignClasses.alignItemCenter,
                              portalTagClasses.root,
                              portalTagClasses.green
                            )}
                          >
                            <Text id="event-live" weight="semibold">
                              {i18n("event_live")}
                            </Text>
                          </div>
                        )}
                      </div>
                      <Text
                        id="event_title"
                        as="h1"
                        className={mergeClasses(
                          portalTextClasses.xLarge,
                          portalTextClasses.eventTitle,
                          eventPageClasses.eventTitle
                        )}
                      >
                        {currentEvent?.title}
                      </Text>
                    </div>
                  </div>

                  {/* Shown in EventInfo on mobile view */}

                  {!mobileView && (
                    <div
                      className={mergeClasses(
                        flexClasses.root,
                        flexClasses.row,
                        flexClasses.rowGapSmall,
                        flexItemClasses.rowPush,
                        eventPageClasses.rightGroup
                      )}
                    >
                      {showRegistrationStatus && <EventAttendingAndCancel />}
                      <EventShareEventButton
                        event={currentEvent}
                        eventUrl={window.location.href}
                      />
                      {isRegistered && (
                        <div>
                          <EventAddToCalenderButton />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {!mobileView && (
                <EventNavBarWeb urlPath={`${routes.event}/`.concat(id)} />
              )}
            </div>

            <div
              className={mergeClasses(
                "event-page-container",
                gridClasses.container,
                eventPageClasses.container
              )}
            >
              <div className={gridClasses.row}>
                <div
                  className={mergeClasses(
                    gridClasses.col,
                    gridClasses.xs12,
                    displayEngagementRail ? gridClasses.lg10 : gridClasses.lg8
                  )}
                >
                  <Switch>
                    <Route exact path={`${path}`}>
                      <EventInfo
                        eventInfo={eventInfoProps}
                        currentEvent={currentEvent}
                      />
                    </Route>
                    <Route exact path={`${path}/${routes.attendee}/:regId`}>
                      <EventInfo
                        eventInfo={eventInfoProps}
                        currentEvent={currentEvent}
                      />
                    </Route>
                    {currentEvent.type !== EventType.WEBINAR &&
                      currentEvent.type !== EventType.TOWNHALL && (
                        <>
                          <Route path={`${path}/sessions`}>
                            <EventSessions />
                          </Route>
                          <Route path={`${path}/speakers`}>
                            <EventSpeakers />
                          </Route>
                          <Route path={`${path}/myagenda`}>
                            <EventMyAgenda currentEvent={currentEvent} />
                          </Route>
                          <Route path={`${path}/${routes.sponsors}`}>
                            <EventSponsors />
                          </Route>
                        </>
                      )}
                    <Route path={`${path}/${routes.attendee}/:regId/cancel`}>
                      <EventInfo
                        eventInfo={eventInfoProps}
                        currentEvent={currentEvent}
                      />
                      <CancelRegistration />
                    </Route>
                    <Redirect to={routes.notFound} />
                  </Switch>
                </div>
                <div
                  className={mergeClasses(
                    gridClasses.col,
                    gridClasses.hiddenMdDown,
                    gridClasses.xs4
                  )}
                >
                  {showRegistrationCard ? <EventRegistrationButton /> : null}
                  {showWebinarLink ? <CreateWebinarCard /> : null}
                </div>
              </div>
            </div>

            <SpeakerProfileDialog
              speaker={displayedSpeaker}
              onDismiss={onDismissSpeakerProfileDialog}
            />

            <EventPageRegistrationModals props={modalProps} />

            {showRegistrationCard && mobileView ? (
              <EventRegistrationButton />
            ) : null}
          </div>
        </EventLayout>
        <PageViewCount />
      </>
    );
  }

  return eventPageDisplay;
};

// TODO: Please move to separate file
/* istanbul ignore next */
export const EventMyAgenda: React.FunctionComponent<{
  currentEvent: IEvent;
}> = () => {
  return <h3>My Agenda page</h3>;
};
